/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/

@import "sass-lib";

@media all and (max-width: 767px) {
  /* global
----------------------------------------------------------------------------------------------*/
  .after_clear:after,
  .wrapper:after,
  section:after {
    clear: both;
    display: block;
    content: " ";
  }
  .before_clear:before {
    clear: both;
    display: block;
    content: " ";
  }
  .wrapper {
    width: auto;
    margin: 0 20px;
    &.w126 {
      width: auto;
      margin: 0 20px;
    }
    .wrapper.w126 {
      margin: 0;
    }
    &.det_des {
      width: auto;
      margin: 0 20px;
    }
  }

  .btn_round {
    line-height: 40px;
    padding: 0 20px;
    min-width: 150px;
  }

  /* header
----------------------------------------------------------------------------------------------*/

  header {
    padding: 12px 0;
    .wrapper {
      margin: 0 20px;
    }
    .logo {
      width: 210px;
    }
    .lf_head {
      margin-top: 5px;
      .burger {
        height: 20px;
        .ico_brger {
          width: 22px;
          height: 17px;
          &.act {
            span:nth-child(1) {
              left: 2px !important;
              top: 6px !important;
            }
            span:nth-child(2) {
              left: calc(50% - 2px) !important;
              top: 6px !important;
              width: 50%;
            }
            span:nth-child(5) {
              left: 2px !important;
              top: 13px !important;
            }
            span:nth-child(6) {
              left: calc(50% - 2px) !important;
              top: 13px !important;
            }
          }
        }
      }
      .ico_src {
        .btn_src {
          width: 20px;
          height: 19px;
          background-size: 40px;
        }
      }
      .lang {
        .act {
          line-height: 16px;
          display: block;
        }
        .drop {
          padding: 1px 5px 0 1px;
        }
      }
    }
    .rh_head {
      nav {
        a {
          &.btn_round {
            line-height: 25px;
            padding: 0 15px;
            font-size: 10px;
          }
        }
      }
    }
    &.home {
      .lf_head {
        .ico_src {
          .btn_src {
            width: 20px;
            height: 19px;
            background-size: 40px;
          }
        }
      }
    }
    &.float {
      padding-top: 12px;
      .logo {
        width: 210px;
      }
    }
  }
  .pop_src {
    padding: 22px 0;
    height: 70px;
    form {
      input[type="text"] {
        font-size: 20px;
      }
      input[type="submit"] {
        width: 20px;
        height: 26px;
        background-size: 20px;
        padding: 0;
      }
    }
  }

  .mnu_wrap {
    padding-top: 50px;
    nav {
      width: 100%;
      ul {
        li {
          margin-bottom: 20px;
          a {
            font-size: 26px;
          }
          .sub {
            position: relative;
            left: 0;
            padding-left: 20px;
            width: auto;
            display: none !important;
            .line {
              display: none;
            }
            ul {
              li {
                a {
                  font-size: 18px;
                }
              }
            }
          }
          &.hv_child {
            &:hover {
              .sub {
                display: none !important;
              }
            }
          }
        }
      }
    }
    .sign {
      display: block;
    }
  }

  /* homepage
----------------------------------------------------------------------------------------------*/

  .slideHome {
    .slider {
      .slide {
        .capt_slide {
          top: 40%;
          h6 {
            font-size: 12px;
            width: auto;
          }
          h1 {
            font-size: 24px;
            line-height: 30px;
            width: auto;
            max-width: 420px;
          }
        }
      }
      .slick-dots {
        top: auto;
        bottom: 20px;
        text-align: left;
        li {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
    &.contentslider {
      margin: 0 20px;
      margin-bottom: 35px;
    }
  }
  .bannerslide {
    .slideHome.contentslider {
      margin: 0 20px;
      margin-bottom: 35px;
    }
  }
  .pack_detail {
    .wrapper.w126 {
      width: auto;
    }
  }
  .sec_journey {
    padding: 25px 0 30px;
    .left {
      text-align: center;
      margin-bottom: 20px;
    }
    .select_journey {
      float: none;
      display: block;
      .sel {
        float: none;
        display: block;
        width: 100%;
        margin-bottom: 15px;
        select {
          width: 100%;
          border: 1px solid #d8d8d8 !important;
          @include borderRadius(10px);
          padding: 11px 20px;
          background-position: 94% center;
        }
        &:nth-of-type(1),
        &:nth-of-type(3) {
          select {
            @include borderRadius(10px);
          }
        }
      }
    }
    .btn_round {
      width: 100%;
      margin-left: 0;
    }
  }

  .home_about {
    padding: 45px 0 50px;
  }
  .txt_top {
    h1 {
      font-size: 30px;
      line-height: 36px;
      margin: 0 20px 20px;
    }
    p {
      margin-bottom: 30px;
    }
  }
  .link_btn {
    margin-top: 30px;
  }

  .why_list {
    .box {
      width: 48%;
      margin-right: 4%;
      min-height: 0;
      &:nth-child(3n) {
        margin-right: 4%;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
      .dec {
        h3 {
          margin-bottom: 15px;
        }
      }
    }
  }
  .home_service {
    .nav_servive {
      overflow: hidden;
      margin: 35px 0;
      .list {
        overflow: auto;
        white-space: nowrap;
        width: auto;
        display: block;
        position: relative;
        height: 31px;
        a {
          padding: 6px 15px;
          margin-right: 5px;
        }
      }
    }
    .slider_service,
    .list_desti {
      .slide {
        margin: 0 5px !important;
      }
      &.slide_Package {
        .item {
          margin: 0 5px !important;
        }
      }
    }
  }

  .slider_service,
  .list_desti {
    .slide {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 35px;
      min-height: 200px;
      &:nth-of-type(3n),
      &:nth-of-type(4n) {
        margin-right: 4%;
      }
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      .img {
        img {
          width: 100%;
          display: block;
        }
        .hov_see {
          opacity: 1;
          @include transform(scale(1));
        }
      }
      .ket {
        padding-right: 10px;
        h3 {
          font-size: 14px;
        }
      }
    }
  }

  .recomend_list {
    .item {
      width: 48%;
      margin-right: 4%;
      min-height: 0;
      &:nth-child(4n),
      &:nth-child(3n) {
        margin-right: 4%;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  .home_travel {
    > img {
      object-position: right;
    }
    .text_travel {
      width: auto;
      margin: 0 40px;
      h3 {
        font-size: 20px;
        line-height: 30px;
      }
      .link_btn {
        margin-top: 40px;
      }
    }
  }
  .home_testimonial {
    .slider_testimo {
      width: auto;
      margin: 0 50px;
    }
  }
  /* middle
----------------------------------------------------------------------------------------------*/
  .middle {
    padding: 50px 0 30px;
    .top_pg {
      .txt_top {
        padding: 45px 0 10px;
      }
      &.list_box {
        .txt_top {
          padding: 30px 0 20px;
        }
      }
    }
  }
  .banner {
    margin-bottom: 35px;
  }
  .banner_top .capt h6 {
    font-size: 12px;
  }
  .banner_top .capt h1 {
    font-size: 24px;
    line-height: 30px;
  }
  .bnnr_dash .info_dash {
    left: 30px;
    right: 30px;
    .ico_profile {
      width: 75px;
      height: 75px;
    }
    .info_profile {
      margin-left: 20px;
      h4 {
        font-size: 20px;
        margin-bottom: 10px;
        line-height: 20px;
        margin-top: 10px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .text_inner {
    padding-bottom: 40px;
    margin-bottom: 30px;
    .lf_txt,
    .rh_txt {
      float: none;
      display: block;
      width: auto;
    }
    .lf_txt {
      h3 {
        margin-bottom: 15px;
      }
    }
    &.btm_bnnr {
      padding-bottom: 40px;
    }
  }

  .sec_why {
    .txt_top {
      padding: 40px 0 0;
    }
  }

  .sec_service {
    padding: 0 0 30px;
    .wrapper {
      width: auto;
      margin: 0 20px;
    }
    .blok_service {
      .img_lf {
        float: none;
        display: block;
        width: auto;
        margin: 0 -20px 25px;
      }
      .right_dec {
        float: none;
        display: block;
        width: auto;
        .text {
          position: relative;
          top: 0;
          @include transform(translateY(0%));
        }
      }
    }
    .txt_top {
      padding: 40px 0;
    }
  }

  .list_result {
    margin-bottom: 35px;
    .item {
      margin-bottom: 35px;
      .img {
        float: none;
        width: auto;
        display: block;
        margin-bottom: 20px;
      }
      .dec_result {
        position: relative;
        float: none;
        display: block;
        top: 0;
        @include transform(translateY(0%));
        width: auto;
        h2 {
          font-size: 20px;
          margin-bottom: 15px;
        }
      }
    }
  }
  .pagging {
    margin-bottom: 20px;
    a {
      font-size: 13px;
    }
    a.prev {
      width: 30px;
      background-position: left top;
      height: 10px;
      &:hover {
        width: 30px;
        height: 10px;
        background-position: left bottom;
        &:after {
          width: 30px;
        }
      }
    }
    a.next {
      width: 30px;
      background-position: left top;
      height: 10px;
      &:hover {
        width: 30px;
        height: 10px;
        background-position: left bottom;
        &:after {
          width: 30px;
        }
      }
    }
  }
  .breadcrumb {
    display: none;
  }
  .list_career {
    margin-bottom: 40px;
    .item {
      padding: 0 0 15px;
      margin-bottom: 20px;
      .jobs {
        float: none;
        display: block;
        max-width: none;
        h3 {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }
      .learn {
        position: relative;
        float: none;
      }
    }
  }

  .form_box {
    .row {
      .g-recaptcha {
        @include transform(scale(0.8));
        position: relative;
        left: -30px;
      }
      .field {
        .col_ck {
          width: auto;
          margin-right: 0;
          margin-bottom: 10px;
          float: none;
          height: auto;
          display: block;
          .txt_type {
            display: block;
          }
        }
        select {
          padding-right: 30px;
        }
        &.full {
          .pass_forgot {
            margin-top: 15px;
          }
        }
      }
    }
    .select2-container--default
      .select2-selection--multiple
      .select2-selection__rendered {
      padding: 6px 10px;
    }
    .select2-container--default
      .select2-selection--multiple
      .select2-selection__choice {
      font-size: 12px;
    }
    hr {
      margin: 25px 0;
    }
    &.form_contact {
      .row {
        label {
          margin-top: 0;
        }
        .g-recaptcha {
          margin-top: 10px;
        }
        input[type="submit"].btn_round {
          margin-top: 25px;
          margin-left: -15px;
        }
      }
    }
    &.plan {
      .row {
        .col-2 {
          width: 100%;
        }
      }
    }
  }
  .thanks_pg,
  .signin_pg {
    padding-top: 50px;
  }

  .thanks_pg {
    .img {
      img {
        left: 0px;
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .form_sign {
    h6 {
      margin-bottom: 10px;
    }
    h1 {
      font-size: 30px;
      margin-bottom: 30px;
    }
    .ck_radio {
      font-size: 14px;
    }
    .link_ck {
      .link_forgot {
        font-size: 14px;
      }
    }
  }

  .nav_destination {
    margin: 20px 0 0;
    position: relative;
    .drop_nav {
      display: block;
      text-align: left;
      font-size: 15px;
      padding: 0 20px 15px 0;
      border-bottom: 3px solid $c_adv;
      cursor: pointer;
      position: relative;
      &:after {
        content: "";
        width: 12px;
        height: 7px;
        background: url("../images/material/arr_select.png") no-repeat;
        display: block;
        position: absolute;
        right: 15px;
        top: 4px;
        bottom: auto;
        margin: auto;
        @include transition(all 0.25s ease-out);
      }
      &.rotate {
        &:after {
          @include transform(rotate(-180deg));
          @include transition(all 0.25s ease-out);
        }
      }
    }
    .list {
      position: absolute;
      left: 20px;
      right: 20px;
      top: 34px;
      display: block;
      background: #fff;
      z-index: 2;
      text-align: left;
      display: none;
      a {
        display: block;
        padding: 15px 0;
        margin: 0;
        &:after {
          display: none;
        }
      }
    }
    &.dash {
      .list {
        left: 0;
        right: 0;
      }
    }
    &.scroll {
      .drop_nav {
        display: none;
      }
      .list {
        position: relative;
        display: block;
        top: 0;
        text-align: center;
        left: 0;
        right: 0;
        overflow: auto;
        white-space: nowrap;
        width: auto;
        height: 31px;
        a {
          display: inline-block;
          padding: 8px 5px;
          margin: 0 10px;
          &:after {
            display: block;
          }
        }
      }
    }
  }
  .detail_destination {
    padding: 40px 0 30px;
  }

  .map_info {
    .map_content {
      float: none;
      width: auto;
      display: block;
      margin-bottom: 25px;
    }
    .info {
      width: auto;
      float: none;
      display: block;
      .label_info {
        padding: 15px 20px;
      }
      .ket_map {
        padding: 15px 20px;
        p {
          margin-bottom: 20px;
        }
      }
    }
  }
  .detail_sec {
    > h3 {
      font-size: 20px;
    }
    &.text_tmt {
      .dec_txt {
        .btn_round {
          line-height: 40px;
          min-width: 140px;
        }
      }
    }
  }
  .item_detail {
    .box {
      .name {
        left: 15px;
        right: 15px;
        bottom: 20px;
      }
    }
  }
  .package_sec {
    .left_package {
      float: none;
      display: block;
      width: auto;
      margin-bottom: 30px;
      .box_checkout .boxs.white .btn_round {
        min-width: 0;
        display: block;
        width: 100%;
      }
    }
    .right_package {
      float: none;
      display: block;
      width: auto;
      .ket_btm {
        text-align: center;
        .btn_round {
          margin: 20px auto 0;
        }
      }
      .box {
        &.book_now {
          .isi_info {
            .booknow_info {
              .row {
                > label {
                  font-size: 14px;
                }
                select {
                  font-size: 14px;
                  padding: 10px 20px;
                }
                &.links {
                  a {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .tab_packages {
    .nav {
      display: none;
    }
    .tab_content {
      .dec_tab {
        display: block;
        .drop_mobile {
          position: relative;
          display: block;
          padding: 15px 0;
          border-bottom: 1px solid #d8d8d8;
          font-size: 15px;
          padding-right: 20px;
          &:after {
            content: "";
            width: 12px;
            height: 7px;
            background: url("../images/material/arr_select.png") no-repeat;
            display: block;
            position: absolute;
            right: 15px;
            top: 0;
            bottom: 0;
            margin: auto;
            @include transition(all 0.25s ease-out);
          }
          &.act_drop {
            border: none;
            &:after {
              @include transition(all 0.25s ease-out);
              @include transform(rotate(-180deg));
            }
            + .text_tab {
              display: block;
            }
          }
        }
        .text_tab {
          display: none;
          padding-top: 20px;
        }
        .drop_txt {
          max-height: none;
          padding-bottom: 10px;
          @include transition(none);
          .drop_btn {
            display: none;
          }
        }
        ul,
        ol {
          li {
            font-size: 14px;
            line-height: 24px;
          }
        }
        .list_diagram {
          .info_tour {
            padding-left: 0;
            .day {
              position: relative;
              display: block;
              margin-left: 5px;
              margin-bottom: 10px;
            }
          }
          &:after,
          &:before {
            display: none;
          }
        }
      }
    }
  }

  .thanks_pg {
    .text_thanks {
      width: auto;
      margin: 0 20px;
      h1 {
        font-size: 36px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .list_step {
    margin-top: 0;
    width: auto;
    .step {
      float: none;
      display: block;
      margin: 0 auto 20px;
      width: 280px;
      text-align: center;
      display: none;
      padding: 8px 15px;
      @include borderRadius(25px !important);
      border: 1px solid #d8d8d8 !important;
      h5 {
        font-size: 16px;
      }
      &.active {
        display: block;
      }
    }
  }
  .box_signup {
    .right_form {
      padding: 30px 15px !important;
    }
  }

  .pg_dashboard {
    .block_lf,
    .block_rh {
      float: none;
      width: auto;
      display: block;
    }
    .block_lf {
      .images {
        margin-bottom: 15px;
        img {
          width: 100%;
        }
      }
    }
    .block_rh {
      .list_trip {
        .box_trip {
          .text_trip {
            left: 20px;
            right: 20px;
          }
        }
      }
    }
  }
  .package_sec {
    &.ckout {
      flex-flow: column;
      -webkit-flex-flow: column;
      -moz-flex-flow: column;
      -ms-flex-flow: column;
      -o-flex-flow: column;
      display: flex;
      .left_package {
        order: 2;
        -webkit-order: 2;
        -moz-order: 2;
        -o-order: 2;
      }
      .right_package {
        order: 1;
        -webkit-order: 1;
        -moz-order: 1;
        -o-order: 1;
      }
    }
  }
  .ck_radio {
    font-size: 15px;
  }

  #pop_hotel {
    .pop_inner {
      .content_pop {
        width: 640px;
        padding: 35px 25px 30px;
        margin: 10% auto;
        .top_popup {
          margin-bottom: 30px;
          h3 {
            font-size: 18px;
          }
          p {
            font-size: 12px;
            line-height: 16px;
          }
        }
        .content_resort {
          .left {
            float: none;
            width: auto;
            display: block;
            margin-bottom: 30px;
          }
          .right {
            float: none;
            display: block;
            width: auto;
          }
        }
      }
    }
  }

  .top_form {
    h2 {
      float: none;
      display: block;
      margin-bottom: 15px;
    }
    .right {
      float: none;
      display: block;
    }
  }
  /* footer
----------------------------------------------------------------------------------------------*/
  .floating-contact {
    a {
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
  footer {
    .top_foot {
      padding: 40px 0;
      flex-direction: column;
      .wrapper {
        flex-direction: column;
      }
      .box {
        &:nth-of-type(1) {
          text-align: center;
          margin: 0 auto;
          float: none;
          display: block;
          margin-bottom: 25px;
        }
      }
      .follow {
        float: none;
        display: block;
        text-align: center;
        flex-direction: column;
        &:after {
          clear: both;
          display: block;
          content: "";
        }
        .item {
          float: none;
          display: inline-block;
          vertical-align: top;
        }
        .sos {
          margin-bottom: 0;
        }
      }
    }
    .btm_foot {
      height: auto;
      padding: 10px;
      text-align: center;
      .left {
        float: none;
        display: block;
        margin-bottom: 6px;
      }
      .right {
        float: none;
        display: block;
        a {
          line-height: 16px;
        }
      }
    }
  }
} /* end media */

@media all and (max-width: 639px) {
  @import "sass-lib";

  /* global
----------------------------------------------------------------------------------------------*/
  .after_clear:after,
  .wrapper:after,
  section:after {
    clear: both;
    display: block;
    content: " ";
  }
  .before_clear:before {
    clear: both;
    display: block;
    content: " ";
  }

  /* header
----------------------------------------------------------------------------------------------*/
  header {
    .lf_head {
      .lang {
        display: none;
      }
      .ico_src {
        position: fixed;
        right: 20px;
        margin-right: 0;
        padding-right: 5px;
        border: none;
      }
      .burger {
        padding-right: 15px;
      }
    }
    .rh_head {
      nav {
        a.btn_round {
          display: none;
        }
      }
    }
    .logo {
      width: 185px;
      right: 0;
      left: 0;
      margin-top: 2px;
    }
    &.float {
      .logo {
        width: 185px;
      }
    }
  }

  /*.mnu_wrap {
	max-height: 400px; overflow: auto;
}*/

  /* homepage
----------------------------------------------------------------------------------------------*/
  .why_list {
    .box {
      width: auto;
      margin-right: 0;
      min-height: 0;
      margin: 0 10% 35px !important;
    }
  }

  .recomend_list {
    .item {
      width: 100%;
      margin-right: 0;
      .img {
        img {
          width: 100%;
          display: block;
        }
      }
      .dec {
        height: auto !important;
      }
    }
  }

  .home_travel {
    height: 400px;
    > img {
      object-position: 80%;
    }
    .text_travel {
      width: auto;
      margin: 0 40px;
      h3 {
        font-size: 18px;
        line-height: 26px;
      }
      .link_btn {
        margin-top: 30px;
        a {
          display: block;
          max-width: 350px;
          margin: auto;
        }
        > span {
          margin: 10px 0;
        }
      }
    }
  }
  .home_testimonial {
    .slider_testimo {
      width: auto;
      margin: 0 30px;
      .slick-arrow {
        &.slick-next {
          right: -40px;
        }
        &.slick-prev {
          left: -40px;
        }
      }
      .slide {
        h3 {
          font-size: 16px;
        }
        p {
          font-size: 14px;
        }
        .info {
          padding-bottom: 10px;
          .left {
            margin-bottom: 15px;
          }
          .left,
          .right {
            float: none;
            display: block;
          }
          .right {
            .user {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  /* middle
----------------------------------------------------------------------------------------------*/

  .sec_recomend {
    .recomend_list {
      .item {
        margin-bottom: 25px;
      }
    }
  }

  .detail_sec {
    &.text_tmt {
      background: #fff;
      height: auto;
      margin: 40px 0 30px;
      .dec_txt {
        width: auto;
        padding: 0;
        h2 {
          width: 100%;
        }
      }
    }
  }

  .box_signup {
    .right_form {
      width: auto;
      padding: 30px 15px;
    }
  }

  /* footer
----------------------------------------------------------------------------------------------*/

  .wrap_popup {
    .pop_inner {
      .content_pop {
        width: auto;
        margin: 5% 20px;
      }
    }
    &.wrap_order {
      .pop_inner {
        .content_pop {
          width: 380px;
          margin: 5% auto;
          min-height: 480px;
        }
      }
    }
  }
  .status_pop {
    .row {
      .col {
        padding: 15px 20px;
        font-size: 14px;
        &:nth-of-type(1) {
          width: 60%;
          margin-right: 3%;
          padding-right: 5px;
        }
        &:nth-of-type(2) {
          width: 37%;
          padding-left: 5px;
        }
        .ico_status {
          padding-left: 20px;
          font-size: 12px;
          &:before {
            width: 12px;
            height: 12px;
          }
        }
      }
      &:nth-child(1) {
        height: 44px;
        .col {
        }
      }
      &.select_btn {
        .col:nth-of-type(1) {
          font-size: 16px;
        }
        .col:nth-of-type(2) {
          text-align: left;
        }
        .col {
          width: 100% !important;
          a.btn_round {
            min-width: 120px;
            line-height: 36px;
            margin-left: 10px;
            &:nth-child(1) {
              margin-left: 6px;
            }
          }
          &.btn {
            padding-right: 5px !important;
          }
        }
      }
    }
    .isi {
      max-height: 250px;
    }
  }

  .top_box {
    .right {
      span {
        font-size: 12px;
      }
    }
  }
  .amount {
    .total_amount {
      font-size: 24px;
      span {
        display: block;
        font-size: 16px;
      }
    }
  }
  .continue {
    font-size: 16px;
    padding: 15px 25px;
  }

  .mnu_wrap {
    .bhs {
      .lg,
      .satuan {
        select {
          font-size: 14px;
        }
      }
      .lg {
        display: inline-block;
      }
    }
  }

  #pop_hotel {
    .pop_inner {
      .content_pop {
        width: 440px;
      }
    }
  }
} /* end media */

@media all and (max-width: 479px) {
  @import "sass-lib";

  /* global
----------------------------------------------------------------------------------------------*/
  .after_clear:after,
  .wrapper:after,
  section:after {
    clear: both;
    display: block;
    content: " ";
  }
  .before_clear:before {
    clear: both;
    display: block;
    content: " ";
  }

  /* homepage
----------------------------------------------------------------------------------------------*/
  .slideHome {
    .slider {
      .slide {
        .capt_slide {
          top: 35%;
        }
      }
    }
  }

  .why_list {
    .box {
      margin: 0 0 35px !important;
    }
  }
  .home_service {
    .slider_service,
    .list_desti {
      .slide {
        margin-right: 0 !important;
        .ket {
          p {
            display: none;
          }
        }
      }
      &.slide_Package {
        .item {
          margin-right: 0 !important;
        }
      }
    }
  }

  .slider_service,
  .list_desti {
    .slide {
      width: 100%;
      margin-right: 0;
      &:nth-of-type(3n),
      &:nth-of-type(4n),
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      .ket {
        h3 {
          font-size: 14px;
        }
      }
    }
    .slick-arrow {
      top: 40%;
    }
  }

  .home_testimonial {
    .slider_testimo {
      .slide {
        .info {
          .right {
            div {
              display: block;
              padding: 0;
              &.dte {
                padding-left: 0;
                margin-left: 0;
                margin-top: 7px;
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  /* middle
----------------------------------------------------------------------------------------------*/

  .form_box {
    .row {
      margin-bottom: 15px;
      label {
        float: none;
        margin: 0 0 5px;
        line-height: 18px;
        max-width: none;
        font-size: 13px;
      }
      .field {
        margin-left: 0;
        input[type="submit"].btn_round.orn {
          width: 100%;
          float: none;
          margin-bottom: 20px;
        }
        &.btn_rw.right {
          float: none;
          display: block;
          width: auto;
        }
        &.full {
          .pass_forgot {
            margin: 20px 0;
            display: block;
            text-align: center;
            float: none;
            font-size: 12px;
          }
          a.btn_round {
            &.left,
            &.right {
              float: none;
              display: block;
              width: auto;
            }
            &.left {
              margin-bottom: 15px;
            }
          }
        }
        .col_ck {
          &.phone_numb {
            .txt_type h6 {
              font-size: 15px;
            }
            .drop_numb {
              margin-left: -20px;
              .select_numb {
                .selectcustom {
                  .btnselect {
                  }
                  .dropselect {
                    .optselect {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.form_contact {
      .row {
        input[type="submit"].btn_round {
          display: block;
          width: 100%;
        }
      }
    }
    &.register {
      .row {
        .field {
          .btn_round {
            margin-left: 0;
            margin-top: 20px;
          }
        }
      }
    }
    &.plan .row .col-2:nth-child(2) .field {
      margin-left: 0;
    }
  }

  .item_detail {
    .box {
      float: none;
      margin-right: 0;
      width: auto;
      max-width: 240px;
      margin: 0 auto 20px !important;
      .round_no {
      }
      .img {
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .package_sec {
    .right_package {
      .ket_btm {
        .btn_round {
          margin: 20px auto 0;
          display: block;
          max-width: none;
        }
      }
    }
    .left_package .box_checkout .boxs {
      padding: 20px 15px;
      .form_box .row .field {
        margin-left: 0;
      }
    }
  }

  .form_sign {
    width: auto;
    margin: 0 20px;
    .ck_radio {
      padding-left: 20px;
      font-size: 13px;
      margin-right: 0;
      margin-top: -5px;
      position: relative;
      display: inline-block;
      input[type="checkbox"] {
        width: 14px;
        height: 14px;
        background-size: 14px;
      }
    }
    .link_ck .link_forgot {
      font-size: 13px;
    }
    .link_btn {
      .btn_round {
        display: block;
        width: 100%;
        float: none;
      }
    }
  }

  .pg_dashboard .block_rh .list_trip .box_trip .img {
    height: 195px;
    overflow: hidden;
  }
  .pg_dashboard .block_rh .list_trip .box_trip .img img {
    max-width: 480px;
  }

  .box_booking {
    .item_booking {
      .top_book {
        .left,
        .right {
          float: none;
          display: block;
          width: auto;
        }
        .left {
          margin-bottom: 5px;
        }
      }
      .text_booking {
        .link {
          text-align: center;
          a.btn_round {
            display: block;
            width: auto;
            float: none;
            margin-right: 0;
            margin-bottom: 10px;
          }
          a.back {
            float: none;
            text-align: center;
            margin-bottom: 15px;
          }
        }
        .ket_detail .col label {
          float: none;
          display: block;
          margin-bottom: 5px;
        }
        .ket_detail .col p,
        .ket_detail .col ul {
          margin-left: 0;
        }
        .ket_detail .col ul {
          li {
            font-size: 15px;
          }
        }
      }
    }
  }

  .wrap_popup {
    &.wrap_order {
      .pop_inner {
        .content_pop {
          width: 280px;
          margin: 5% auto;
          min-height: 480px;
        }
      }
    }
  }
  .amount {
    padding: 10px 10px 10px 15px;
    > label {
      display: block;
      float: none;
      margin-bottom: 10px;
    }
    .total_amount {
      margin-left: 0;
      text-align: left;
      font-size: 20px;
      span {
        display: inline-block;
      }
    }
  }

  .top_box {
    margin-bottom: 40px;
    .left {
      float: none;
    }
    .right {
      float: none;
      display: block;
      margin-top: 10px;
      span {
        line-height: 20px;
        padding: 0;
        text-align: center;
      }
    }
  }
  .form_box {
    .row {
      &.sum {
        margin-bottom: 0;
        .field {
          &.full {
            .block {
              width: 48%;
              margin-right: 4%;
              margin-bottom: 15px;
              h6 {
                padding-right: 0;
                text-align: left;
                padding-left: 18px;
              }
              &:nth-of-type(2n) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

  #pop_hotel {
    .pop_inner {
      .content_pop {
        width: 280px;
        .content_resort {
          .list-img {
            .slide_big {
              margin-bottom: 8px;
            }
            .thumb_slide .thumb {
              margin: 0 3px;
            }
          }
        }
      }
    }
  }

  /* footer
----------------------------------------------------------------------------------------------*/

  footer {
    .top_foot {
      .box:nth-of-type(1) {
        width: auto;
        p {
          line-height: 24px;
        }
      }
      .follow {
        .item {
          float: none;
          display: block;
          &:nth-child(2) {
            width: 180px;
            margin: 25px auto 0;
            h6 {
              margin-bottom: 10px;
            }
          }
        }
        .sos {
          margin-bottom: 0;
        }
      }
    }
  }
} /* end media */
